<template>
    <Pop ref="pop" class="hospital" :clickClose="true">
        <div slot="popContent">
            <!-- <Header backType="white" :back="true" /> -->
            <div class="headTop">
                <Header backType="blank" :back="true" :backEvent="true" @backEvent="back"></Header>
                <div class="publicHead">
                    <p>选择医院</p>
                </div>
                <searchRow placeholder="请输入医院名称" v-model="keyword"></searchRow>
            </div>

            <ScrollBar
                class="opOrderListScroll"
                :style="listStyle"
                @onTop="scrollTop" 
                :loadType="loadType"
            >
                <div slot="scrollBar">
                    <div class="hospitalList" v-if="hospitalList.length">
                        <ul>
                            <li
                                v-for="item in hospitalList"
                                :key="item.rowId"
                                @click="chooseHos(item)"
                            >{{item.name}}</li>
                        </ul>
                    </div>
                    <div class="noData" v-if="!hospitalList.length&&loadType==''&&!isFirst">暂无数据</div>
                </div>
            </ScrollBar>
        </div>
    </Pop>
</template>
<script>
import { miceService } from "@/service/miceService.js";
import { mapGetters } from "vuex";
export default {
    data() {
        return {
            keyword: "",
            hospitalList: [],
            loadType: "",
            isFirst: true,
            listStyle: {}
        };
    },
    watch: {
        keyword: function () {
            this.getHospitalList();
        },
    },
    created() {
        // this.setStyle();
    },
    computed: {
        ...mapGetters(["event"]),
    },
    methods: {
        getHospitalList(regionId) {
            let params = {
                // cityName: '北京市',
                cityName: this.event.cityDictVal,
                tenantId: this.event.tenantId,
                keyword: this.keyword,
            };
            if(regionId){
                params = {
                    cityId: regionId, //根据前面选择的城市guid
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    searchInput: this.hospitalName
                }
                miceService.PagingTenantHospital(params).then(res => {
                    this.isFirst = false;
                    if (res.success) {
                        this.hospitalList = res.content.rows; 
                        this.loadType = '';
                    }
                });
                return
            }
            miceService.getHospitalList(params).then((res) => {
                this.isFirst = false;
                this.loadType = '';
                this.hospitalList = res.data;
            });
        },
        openPop(regionId) {
            this.getHospitalList(regionId);
            this.$refs.pop.changePop();
            // setTimeout(() => {
            //     this.getStyle()
            // }, 100)
        },
        setStyle() {
            let height = window.innerHeight + "px";
            this.pageStyle = {
                height: height,
                background: "#fff",
            };
        },
        getStyle() {
            let head = document.getElementsByClassName('headTop')[0].offsetHeight;
            let hei = window.innerHeight - head; 
            this.listStyle = {
                height: hei + 'px',
                overflow: 'scroll'
            }
        },
        back() {
            this.$refs.pop.changePop();
        },
        chooseHos(item) {
            this.$emit("onOk", item);
            this.back();
        },
        scrollTop() {
            this.loadType = 'top';
            this.getHospitalList();
        }
    },
};
</script>
<style lang="scss">
.hospital {
    .popContent {
        top: 0;
        background: #fff;
    }
    .publicHead {
        margin-bottom: 0.25rem !important;
    }

    .headTop {
        padding-bottom: 0.2rem;
        text-align: center !important;
    }
    .opSearchSvg {
        position: absolute;
        left: .38rem;
        top: .11rem;
        width: .15rem !important;
        height: .15rem !important;
    }

    .hospitalList ul {
        background: #fff;
    }
    .opOrderListScroll {
        position: absolute;
        width: 100%;
        overflow: scroll;
        bottom: 0;
        top: 1.66rem;
    }
    .hospitalList ul li{
        text-align: left;
        padding: .16rem 0;
        margin: 0 .26rem;
        border-bottom: 1px solid #f5f5f5;
        color: #13161B;
        font-size: .14rem;
    }
    .noData{
        margin-top: 2.5rem;
    }
}
</style>